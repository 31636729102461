import {
  BaseStepIdEnum,
  FieldEntityTypeEnum,
  FieldPropertyTypeEnum,
  FieldTypeEnum,
  StepConfig,
  StepTypeEnum,
} from 'shared-domain';

export const stepsConfig: StepConfig[] = [
  {
    id: BaseStepIdEnum.company_search,
  },
  {
    id: BaseStepIdEnum.company_list,
  },
  {
    id: BaseStepIdEnum.company_edit,
  },
  {
    id: 'company_address',
    type: StepTypeEnum.form,
    config: {
      fields: [
        {
          id: 'street_address',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'postal_code',
          nested: 'address',
          type: FieldTypeEnum.text,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
        {
          id: 'country',
          nested: 'address',
          type: FieldTypeEnum.country,
          isRequired: true,
          isEnabled: true,
          entityType: FieldEntityTypeEnum.company,
          propertyType: FieldPropertyTypeEnum.default,
        },
      ],
    },
  },
  {
    id: BaseStepIdEnum.affiliated_companies_list,
  },
  {
    id: BaseStepIdEnum.individuals_list,
    config: { hasApplicant: false },
  },
];
